@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Concert+One&family=Dancing+Script:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sura:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Concert+One&family=Dancing+Script:wght@400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sura:wght@400;700&display=swap');

body{
    font-family: "Montserrat", serif;
    font-optical-sizing: auto;
    font-weight: 400px;
    font-style: normal;
}

h1 {
    font-family: "Sura", serif;
    font-weight: 400;
    font-style: normal;
  }
 

  .signupimg{
    display: flex;
    /* align-items: center;
    justify-content: center; */
    height: 100vh;
    padding: 2rem;
    background-image: url('./assets/unsplash_L8tWZT4CcVQ.png');
    background-size: cover;
    background-position: center;
   
    
} 
  

  .signupimg h1{
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  @keyframes popOut {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    60% {
      opacity: 0.7;
      transform: translateX(10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes borderReveal {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
  
  .animation-pop-out {
    animation: popOut 1s ease forwards;
    width: 13rem;
  }
  
  .group-hover .animation-border {
    animation: borderReveal 1s ease forwards;
  }
  

  .swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.swiper-button-prev {
  left: -50px;
}

.swiper-button-next {
  right: -50px;
}

.swiper-button-next:after{
  display: none;
}
.swiper-button-prev:after{
  display: none;
}


.shadow{
  box-shadow: 1px 1px 10px 1px;
  
}

input {
  outline: none;
}




  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .text-gray-500 {
    color: #6b7280;
  }


  .transform {
    transform: translateY(-50%);
  }

  .left-3 {
    left: -0.1rem; /* Adjust this value if needed */
  }

  .pl-10 {
    padding-left: 2.5rem; /* Add space for the symbol */
  }
